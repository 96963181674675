import {Dialog, Slide} from '@mui/material';
import styles from './CreateBot.module.css';
import {TransitionProps, useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import backImg from './images/back.svg';
import cn from 'classnames';
import {AvatarSelector} from './AvatarSelect/AvatarSelector';
import {NamePronoun, Pronoun} from './NamePronoun/NamePronoun';
import {VoiceSelectorRow} from '../../common-lib/src/components/Selectors/VoiceSelectorRow/VoiceSelectorRow';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {GeneratePhotos, PublicBot} from './PublicBot/PublicBot';
import {AiAvatar} from './AiAvatar/AiAvatar';
import {webApi} from '../../api/webApi';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {api} from '../../common-lib/src/api/Api';
import {AppContext} from '../../App';
import {CreateBotMobile} from './CreateBotMobile/CreateBotMobile';
import {BotifyTextArea} from './BotifyTextArea/BotifyTextArea';
import {BotData} from '../../api/types';
import {
	convertPronoun,
	convertVoicesFullDataToVoiceDataType,
} from './CreateBot.utils';
import {ChatPhotoStyle} from '../../common-lib/src/api/Api.types';
import {webEventsApi} from '../../amplitude/webEvents';
import {VoiceDataType} from '../../common-lib/src/data/voicesData.types';

type Props = {
	openned: boolean;
	onClose: () => void;
	bot: BotData | null;
	botProps: BotProps;
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const getPronoun = (pronoun: Pronoun) => {
	switch (pronoun) {
		case 'He':
			return 'he/him';
		case 'She':
			return 'she/her';
		case 'They':
			return 'they/them';
		case 'N/A':
			return null;
	}
};

export type Errors = {
	photo?: boolean;
	name?: boolean;
	description?: boolean;
	appearance?: boolean;
	firstMessage?: boolean;
};

export type BotProps = ReturnType<typeof useCreateBotPopup>;

export const useCreateBotPopup = ({
	onClose,
	bot,
}: {
	onClose?: () => void;
	bot: BotData | null;
}) => {
	const {user, setIsPaywallModalOpened} = useContext(AppContext);
	const [name, setName] = useState('');
	const [pronoun, setPronoun] = useState<Pronoun>('N/A');

	const [voice, setVoice] = useState<VoiceDataType | null>(null);
	const [voices, setVoices] = useState<VoiceDataType[]>([]);

	const [isPublic, setIsPublic] = useState(true);
	const [isAIPopupOpen, setIsAIPopupOpen] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [file, setFile] = useState<File | null>(null);
	const [generatePhotos, setGeneratePhotos] = useState(false);

	const [description, setDescription] = useState('');
	const [appearance, setAppearance] = useState('');
	const [firstMessage, setFirstMessage] = useState('');

	const [chatPhotoStyle, setChatPhotoStyle] = useState<ChatPhotoStyle>(null);

	const [isLoading, setIsLoading] = useState(false);
	const [isCheckLoading, setIsCheckLoading] = useState(false);
	const [isAvatarLoading, setIsAvatarLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const [avatarData, setAvatarData] = useState<{
		idle_url?: string;
		image_url: string;
	} | null>(null);
	const [errors, setErrors] = useState<Errors | null>(null);

	const isAllFilled =
		!!name.trim() && !!description.trim() && !!appearance.trim() && avatarData;

	const handleCheck = async (
		field: 'name' | 'description' | 'appearance' | 'firstMessage',
		value: string | string[],
		skipSetErrors = false
	) => {
		if (!skipSetErrors) {
			resetErrors(field);
		}

		if (!value) {
			return;
		}

		const body = {
			bot_name: name || null,
			description: description || null,
			appearance: appearance || null,
			greeting: firstMessage || null,
		} as Record<string, string | string[]>;
		if (field === 'name') {
			body['bot_name'] = value;
		}
		if (field === 'description') {
			body['description'] = value;
		}
		if (field === 'appearance') {
			body['appearance'] = value;
		}
		if (field === 'firstMessage') {
			body['greeting'] = value;
		}

		try {
			setIsCheckLoading(true);
			const {is_inappropriate_bot} = await api.checkBot(body);

			if (is_inappropriate_bot && !skipSetErrors) {
				const updatedErrors: Errors = {...errors};
				updatedErrors[field] = true;
				setErrors(updatedErrors);
			}

			return is_inappropriate_bot;
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong', {variant: 'error'});
		} finally {
			setIsCheckLoading(false);
		}
	};
	const handleCheckName = (value: string, skipSetErrors?: boolean) =>
		handleCheck('name', value, skipSetErrors);
	const handleCheckDescription = (value: string, skipSetErrors?: boolean) =>
		handleCheck('description', value, skipSetErrors);
	const handleCheckAppearance = (value: string, skipSetErrors?: boolean) =>
		handleCheck('appearance', value, skipSetErrors);
	const handleCheckFirstMessage = (value: string, skipSetErrors?: boolean) =>
		handleCheck('firstMessage', value, skipSetErrors);

	const handleSaveClick = async () => {
		try {
			setIsLoading(true);
			const body = {
				name,
				private: !isPublic,
				pronoun: getPronoun(pronoun),
				voice: voice?.name || null,
				avatarUrl: avatarData?.image_url || null,
				idleUrl: avatarData?.idle_url || null,
				description,
				appearance,
				greeting: firstMessage,
				generatePhotos,
				isSexual: false,
				chatPhotosType: chatPhotoStyle,
			};

			resetErrors('name');

			const {is_inappropriate_bot, is_sexual_bot} = await api.checkBot({
				bot_name: body.name,
				description,
				appearance,
				greeting: firstMessage,
			});

			body.isSexual = is_sexual_bot;

			if (is_inappropriate_bot) {
				const updatedErrors: Errors = {...errors};
				updatedErrors.name = true;
				updatedErrors.description = true;
				updatedErrors.appearance = true;
				updatedErrors.firstMessage = true;

				setErrors(updatedErrors);
				setIsLoading(false);
				enqueueSnackbar('Check fields', {variant: 'error'});
				return;
			}

			const res = bot
				? await webApi.updateBot(bot.id, body)
				: await webApi.createBot(body!);

			if (!bot && res?.data?.attributes) {
				const b = res as {data: BotData};
				webEventsApi.customBotCreated({
					chat_id: b.data.attributes.firebaseChatId,
					user_id: api.getUserId(),
					is_private: b.data.attributes.private,
					idle_available: !!b.data.attributes.idleUrl,
					idle_turned_on: true,
				});
			}

			if (!res?.data?.id) {
				const text = res?.error?.message || 'Something went wrong';
				enqueueSnackbar(text, {variant: 'error'});
				return;
			}

			onClose?.();
			window.location.href = `/bot_${res.data.id}/chat`;
		} catch (e) {
			console.log(e);
			enqueueSnackbar('Something went wrong', {variant: 'error'});
		} finally {
			setIsLoading(false);
		}
	};

	const resetErrors = (
		field: 'name' | 'photo' | 'description' | 'appearance' | 'firstMessage'
	) => {
		if (!errors || !errors[field]) {
			return;
		}
		const newErrors = {...errors};
		delete newErrors[field];
		setErrors(newErrors);
	};

	const hasErrors = Object.keys(errors || {}).length > 0;

	useEffect(() => {
		const getAvatarData = async () => {
			setIsAvatarLoading(true);
			try {
				const result = await api.generateAnimation(file!);

				if (result?.message === 'Child detected!') {
					enqueueSnackbar('Child detected! Please upload a different photo', {
						variant: 'error',
					});
					setErrors({
						...(errors || {}),
						photo: true,
					});
				} else if (result?.message) {
					enqueueSnackbar(result.message, {
						variant: 'error',
					});
					setErrors({
						...(errors || {}),
						photo: true,
					});
				} else if (result?.idle_url) {
					resetErrors('photo');
					setAvatarData(result);
				} else if (result?.image_url && result?.is_gallery_available) {
					enqueueSnackbar('Face not detected! Please upload a different photo', {
						variant: 'error',
					});
					setErrors({
						...(errors || {}),
						photo: true,
					});
				} else {
					enqueueSnackbar('Something wrong with avatar generation', {
						variant: 'error',
					});
					setErrors({
						...(errors || {}),
						photo: true,
					});
				}
			} catch (e) {
				console.log(e);
				enqueueSnackbar('Something wrong with avatar generation', {
					variant: 'error',
				});
				setErrors({
					...(errors || {}),
					photo: true,
				});
			} finally {
				setIsAvatarLoading(false);
			}
		};

		if (!file) {
			if (bot) {
				return;
			}
			setAvatarData(null);
		} else {
			getAvatarData();
		}
	}, [file]);

	const handleImageChange = (
		file: File | null,
		value: string | null,
		style: ChatPhotoStyle
	) => {
		setImage(value);
		setFile(file);
		setChatPhotoStyle(style);
	};

	useEffect(() => {
		if (errors?.name) {
			resetErrors('name');
		}
	}, [name]);

	useEffect(() => {
		if (errors?.description) {
			resetErrors('description');
		}
	}, [description]);

	useEffect(() => {
		if (errors?.appearance) {
			resetErrors('appearance');
		}
	}, [appearance]);

	useEffect(() => {
		if (errors?.firstMessage) {
			resetErrors('firstMessage');
		}
	}, [firstMessage]);

	useEffect(() => {
		if (avatarData) {
			setGeneratePhotos(!!avatarData);
		}
	}, [avatarData]);

	const handleChangeGeneratePhotos = (v: boolean) => {
		if (!avatarData) {
			return;
		}
		setGeneratePhotos(v);
	};

	const handleChangeIsPublic = (v: boolean) => {
		if (!user?.isPaid) {
			setIsPaywallModalOpened(true);
			return;
		}
		setIsPublic(v);
	};

	useEffect(() => {
		if (!bot || !voices.length) {
			return;
		}

		const {attributes} = bot;
		setName(attributes.name);
		setPronoun(attributes.pronoun ? convertPronoun(attributes.pronoun) : 'N/A');

		const selectedVoice = attributes.voice
			? voices.find(
					(v) => v.name === attributes.voice?.data?.attributes.name
			  ) || voices[0]
			: voices[0];

		setVoice(selectedVoice);
		setIsPublic(!attributes.private);
		setDescription(attributes.description || '');
		setAppearance(attributes.appearance || '');
		setFirstMessage(attributes.greeting || '');
		setAvatarData({
			idle_url: attributes.idleUrl,
			image_url: attributes.avatarUrl,
		});
		setGeneratePhotos(attributes.generatePhotos);
		setImage(attributes.avatarUrl);
	}, [bot, voices]);

	useEffect(() => {
		if (voice || !voices.length || bot) {
			return;
		}
		setVoice(voices[0]);
	}, [voices, bot]);

	useEffect(() => {
		const getVoices = async () => {
			const voices = await webApi.getVoices().catch((e) => {
				console.error(e);
				enqueueSnackbar('Failed to load voices', {variant: 'error'});
				return {data: []};
			});
			setVoices(convertVoicesFullDataToVoiceDataType(voices.data));
		};
		getVoices();
	}, []);

	return {
		name,
		setName,
		pronoun,
		setPronoun,
		voice,
		setVoice,
		isPublic,
		setIsPublic: handleChangeIsPublic,
		isAIPopupOpen,
		setIsAIPopupOpen,
		image,
		handleImageChange,
		isAllFilled,
		handleSaveClick,
		isLoading: isLoading || !voices.length,
		isAvatarLoading,
		avatarData,
		errors,
		hasErrors,
		description,
		setDescription,
		appearance,
		setAppearance,
		firstMessage,
		setFirstMessage,
		handleCheckName,
		handleCheckDescription,
		handleCheckAppearance,
		handleCheckFirstMessage,
		generatePhotos,
		handleChangeGeneratePhotos,
		isCheckLoading,
		voices,
	};
};

export const EditTextField = ({
	value,
	onChange,
	placeholder,
	title,
	onCheck = () => {},
	hasError = false,
	disabled = false,
	oneLine = false,
	className,
}: {
	value: string;
	onChange: (value: string) => void;
	placeholder: string;
	title?: string;
	onCheck?: (value: string) => void;
	hasError?: boolean;
	disabled?: boolean;
	oneLine?: boolean;
	className?: string;
}) => {
	return (
		<div className={cn(styles.block, className)}>
			{title && <h3 className={styles.blockTitle}>{title}</h3>}
			<div className={styles.blockContent}>
				<BotifyTextArea
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					onCheck={onCheck}
					showError={hasError}
					disabled={disabled}
					className={cn(oneLine && styles.oneLine)}
				/>
			</div>
		</div>
	);
};

const CreateBotDesktop = ({openned, onClose, bot, botProps}: Props) => {
	const {
		name,
		setName,
		pronoun,
		setPronoun,
		voice,
		setVoice,
		isPublic,
		setIsPublic,
		isAIPopupOpen,
		setIsAIPopupOpen,
		image,
		handleImageChange,
		isAllFilled,
		handleSaveClick,
		isLoading,
		isAvatarLoading,
		avatarData,
		errors,
		hasErrors,
		description,
		setDescription,
		appearance,
		setAppearance,
		firstMessage,
		setFirstMessage,
		handleCheckName,
		handleCheckDescription,
		handleCheckAppearance,
		handleCheckFirstMessage,
		generatePhotos,
		handleChangeGeneratePhotos,
		isCheckLoading,
		voices,
	} = botProps;

	return (
		<Dialog
			fullScreen
			open={openned}
			onClose={onClose}
			TransitionComponent={Transition}
			sx={{
				height: '100dvh',
				backgroundColor: 'black',
				boxSizing: 'border-box',
				width: '100%',
			}}
			className={styles.dialog}
		>
			<div className={styles.container}>
				<div className={styles.header}>
					<img
						src={backImg}
						alt="back"
						onClick={onClose}
						className={styles.back}
					/>
					<h2 className={styles.title}>
						{bot ? 'Edit AI Character' : 'Create AI Character'}
					</h2>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Avatar</h3>
					<div className={cn(styles.blockContent, styles.avatarBlock)}>
						<AvatarSelector
							onGenerateClick={() => setIsAIPopupOpen(true)}
							value={image}
							onChange={handleImageChange}
							showError={errors?.photo}
							loading={isAvatarLoading}
							avatarData={avatarData}
						/>
					</div>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Name & Pronouns</h3>
					<div className={styles.blockContent}>
						<NamePronoun
							name={name}
							onChangeName={setName}
							onChangePronoun={setPronoun}
							pronoun={pronoun}
							showError={errors?.name}
							onCheck={handleCheckName}
							disabled={(hasErrors && !errors?.name) || isCheckLoading}
						/>
					</div>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Description</h3>
					<div className={styles.blockContent}>
						<BotifyTextArea
							value={description}
							onChange={setDescription}
							placeholder="Describe details of your bot's personality and backstory. Describe the situation you want them in and the kinds of things they want to talk about"
							onCheck={handleCheckDescription}
							showError={errors?.description}
							disabled={(hasErrors && !errors?.description) || isCheckLoading}
						/>
					</div>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Appearance</h3>
					<div className={styles.blockContent}>
						<BotifyTextArea
							value={appearance}
							onChange={setAppearance}
							placeholder="Describe details of your bot’s look: gender, age, race & skin color, body type, hairstyle & color, eye color, and clothes style. They will be used in image prompts for photos generated by the bot"
							onCheck={handleCheckAppearance}
							showError={errors?.appearance}
							disabled={(hasErrors && !errors?.appearance) || isCheckLoading}
						/>
					</div>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>First message</h3>
					<div className={styles.blockContent}>
						<BotifyTextArea
							value={firstMessage}
							onChange={setFirstMessage}
							placeholder="The first message will be sent by the bot when starting a new chat. It’s very important to set the scene and steer future conversation"
							onCheck={handleCheckFirstMessage}
							showError={errors?.firstMessage}
							disabled={(hasErrors && !errors?.firstMessage) || isCheckLoading}
						/>
					</div>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Settings</h3>
					<div className={styles.blockContent}>
						<div className={styles.settingsRow}>
							<VoiceSelectorRow
								position="top"
								onSelect={setVoice}
								selectedVoice={voice}
								withIcon
								voices={voices}
							/>
						</div>
					</div>
				</div>
				<div className={styles.block} style={{marginTop: '10px'}}>
					<div className={styles.blockContent}>
						<GeneratePhotos
							value={generatePhotos}
							onChange={handleChangeGeneratePhotos}
						/>
					</div>
				</div>
				<div className={styles.block}>
					<h3 className={styles.blockTitle}>Visibility</h3>
					<div className={styles.blockContent}>
						<PublicBot value={isPublic} onChange={setIsPublic} />
					</div>
				</div>
				<InButton
					id="create-character-button"
					isFilled
					className={styles.createButton}
					isDisabled={!isAllFilled || hasErrors}
					onClick={handleSaveClick}
				>
					{bot ? 'Edit AI Character' : 'Create AI Character'}
				</InButton>
			</div>
			<AiAvatar
				openned={isAIPopupOpen}
				onClose={() => setIsAIPopupOpen(false)}
				onChange={handleImageChange}
			/>
			{isLoading && <Spinner />}
		</Dialog>
	);
};

export const CreateBotPopup = ({
	openned,
	onClose,
	bot,
}: Omit<Props, 'botProps'>) => {
	const {isMobile} = useContext(AppContext);

	const botProps = useCreateBotPopup({onClose, bot});

	if (isMobile) {
		return (
			<CreateBotMobile
				openned={openned}
				onClose={onClose}
				bot={bot}
				botProps={botProps}
			/>
		);
	}

	return (
		<CreateBotDesktop
			openned={openned}
			onClose={onClose}
			bot={bot}
			botProps={botProps}
		/>
	);
};
